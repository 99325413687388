import React, { useContext, useEffect } from "react";
import BallAnimation from "../Animations/BallAnimation";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { animatePageOut } from "../Utils/Page-transitions";
import Context from "../../../Context/Context";
import { useNavigate } from "react-router-dom";

const About = () => {
  const history = useNavigate();
  const context = useContext(Context);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let start = "top 80%";
    let end = "top 50%";
    if (context?.width > 600) {
      gsap.fromTo(
        "#leftAboutSection span",
        { opacity: 0, y: -100 },
        {
          opacity: 1,
          y: 0,
          stagger: 0.3,
          scrollTrigger: {
            trigger: "#leftAboutSection",
            start,
            end,
            scrub: true,
            scroller: ".mainApp",
          },
        }
      );
      gsap.fromTo(
        "#rightAboutSection",
        { opacity: 0, x: 100 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: "#leftAboutSection",
            start,
            end,
            scrub: true,
            scroller: ".mainApp",
          },
        }
      );
      gsap.fromTo(
        "#aboutSectionBall",
        { opacity: 0, scale: 0, y: 100 },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          scrollTrigger: {
            trigger: "#leftAboutSection",
            start,
            end,
            scrub: true,
            scroller: ".mainApp",
          },
        }
      );
      gsap.fromTo(
        "#aboutSectionBall",
        { y: 120 },
        {
          y: 0,
          scrollTrigger: {
            trigger: "#leftAboutSection",
            start,
            end: "top 0%",
            scrub: true,
            scroller: ".mainApp",
          },
        }
      );
    } else {
    }
  }, []);

  return (
    <div
      data-scroll-section
      className="bg-white px-5 md:px-[12vw] pt-[10vw] md:pt-[5vw] pb-[8vw] md:pb-[15vw]"
    >
      <div className="flex md:flex-row flex-col items-start justify-between">
        <h1
          className="w-full md:w-7/12 md:block hidden text-xl md:text-4xl md:leading-[50px]"
          id="leftAboutSection"
        >
          {"Welcome to Bytedocker, your trusted technology partner dedicated to transforming businesses through powerful digital solutions. "
            .split("")
            .map((word, i) => {
              switch (word) {
                case "Bytedocker,":
                  return <Block key={i} word={word} />;
                default:
                  return (
                    <span key={i} className="">
                      {word}
                    </span>
                  );
              }
            })}
        </h1>
        <h1
          className="w-full md:w-8/12 md:hidden text-xl md:text-4xl md:leading-[50px]"
          id="leftAboutSection"
        >
          Welcome to Bytedocker, your trusted technology partner dedicated to
          transforming businesses through powerful digital solutions.
        </h1>
        <p
          className="w-full md:text-base text-sm md:mt-0 mt-1 md:w-3/12"
          id="rightAboutSection"
        >
          Our passion for design, coding, and interactive experiences uniquely
          positions us in the web development landscape.
        </p>
      </div>
      <div
        className="md:absolute right-[12vw] flex items-end justify-end md:mt-0 mt-5 z-30"
        id="aboutSectionBall"
      >
        <BallAnimation
          className="w-[23vw] md:w-[10vw] h-[23vw] md:h-[10vw]"
          ballSize={"w-[23vw] md:w-[10vw] h-[23vw] md:h-[10vw] md:text-2xl"}
          text="About us"
          cnt={2}
          onClick={(e) => {
            context?.setScrollPosition(0);
            animatePageOut("/about", history, "About");
          }}
        />
      </div>
    </div>
  );
};

const Block = ({ word, link }) => {
  return (
    <span
      onMouseMove={(e) => {
        gsap.to(".mouse", {
          width: "70px",
          height: "70px",
          border: "5px solid #E6F0FF",
        });
      }}
      onMouseLeave={(e) => {
        gsap.to(".mouse", {
          width: "25px",
          height: "25px",
          border: "1px solid #000",
        });
      }}
      className="text-newBlue mr-2 break-words cursor-pointer relative z-40"
    >
      {word.slice(0, word?.length - 1)},
    </span>
  );
};

export default About;
